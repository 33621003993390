import React, { Component } from 'react';
import styles from './useCasesComponent.module.css';
import applyWrappers from 'utils/applyWrappers';
import topCircle from 'images/usecases-bg-1.png';
import bottomCircle from 'images/usecases-bg-2.png';
import sales from 'images/usecase-sales.png';
import hr from 'images/usecase-hr.png';
import product from 'images/usecase-product.png';
import content from 'images/usecase-content.png';

class UseCasesComponent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			currentUseCaseId: 0,
			useCases: [
				{
					title: "Sales & Marketing",
					icon: sales,
					description: "Ever wondered how difficult it is to manage sales decks and different versions of marketing powerpoints? With easy-to-use version management features that would make those hassles of the past."
				},
				{
					title: "Product Teams",
					icon: hr,
					description: "Krawler’s advanced metadata generation models assist Product Managers to efficiently search across product documentation, archives, and business requirement documents"
				},
				{
					title: "Human Resources",
					icon: product,
					description: "The advanced AI-based NLP models help you in automatically segmenting your compliances and policy documents, auto-creating relevant sections for you to search efficiently. With a simple search, anyone can now get to that specific policy section."
				},
				{
					title: "Content & Design",
					icon: content,
					description: "Now with Krawler’s Playlist Features, your team can create, curate and share content playlists, with just a few clicks, improving your team’s productivity and collaboration."
				}
			]
		}
	}

	componentDidMount() {
		this.startCarousel();
	}

	componentWillUnmount() {
		clearTimeout(this.carouselInterval);
	}

	startCarousel = () => {
        this.carouselInterval = setInterval(() => {
            this.transitionSlides();
        }, 10000);
	};

	transitionSlides = () => {
		this.setState(prevState => {
			const { useCases, currentUseCaseId } = prevState;
			return { currentUseCaseId: (currentUseCaseId + 1) % useCases.length }
		});
	};

	setSelectedUseCase = (useCaseId) => {
		this.setState({ currentUseCaseId: useCaseId }, () => {
			clearTimeout(this.carouselInterval);
			this.startCarousel();
		});
	}

	renderUseCases = () => {
		const { useCases, currentUseCaseId } = this.state;
		return useCases.map((useCase, index) => {
			const isSelected = index === currentUseCaseId;
			const { title } = useCase;
			return (
				<div
					key={title}
					styleName="usecase"
					onClick={this.setSelectedUseCase.bind(this, index)}
				>
					<div>{title}</div>
					{isSelected && <div styleName="bottom-border">
						<div styleName="bottom-border-fill"></div>
					</div>}
				</div>
			);
		});
	}

	render() {
		const { useCases, currentUseCaseId } = this.state;
		const { title, icon, description } = useCases[currentUseCaseId];
		return (
			<div ref={this.props.myref} styleName="container">
				<div styleName="top-circle">
					<img src={topCircle} alt="background" />
				</div>
				<div styleName="bottom-circle">
					<img src={bottomCircle} alt="background" />
				</div>
				<div styleName="text-container">
					<div styleName="title-container">
						<div styleName="main-text">More productivity in a single search</div>
						<div styleName="sub-text">Search from an ocean of content in a few simple clicks. Get productive with your team by focusing on important tasks and leave the complicated task of searching to us!</div>
					</div>
					<div styleName="usecases-container">
						{this.renderUseCases()}
					</div>
					<div key={title} styleName="usecase-container fade">
						<div styleName="usecase-image">
							<img src={icon} alt={title} />
						</div>
						<div styleName="usecase-description">{description}</div>
					</div>
				</div>
			</div>
		)
	}
}

export default applyWrappers(UseCasesComponent, styles);