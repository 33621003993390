import React, { Component } from 'react';
import IntroductionComponent from 'components/introductionComponent';
import FeaturesComponent from 'components/featuresComponent';
import UseCasesComponent from 'components/useCasesComponent';
import TechComponent from 'components/techComponent';
import IntegrationsComponent from 'components/integrationsComponent';
import ContactComponent from 'components/contactComponent';
import Footer from 'components/footer';

class App extends Component {
	constructor(props){
		super(props);
		this.containerRef = React.createRef();
		this.featuresRef = React.createRef();
		this.useCasesRef = React.createRef();
		this.integrationsRef = React.createRef();
		this.contactRef = React.createRef();
	}

	onHeaderTabClick = (tab) => {
		switch(tab) {
			case 'features':
				this.featuresRef.current.scrollIntoView({behavior: 'smooth'});
				break;
			case 'useCases':
				this.useCasesRef.current.scrollIntoView({behavior: 'smooth'});
				break;
			case 'integrations':
				this.integrationsRef.current.scrollIntoView({behavior: 'smooth'});
				break;
			case 'contact':
				this.contactRef.current.scrollIntoView({behavior: 'smooth'});
				break;
			default:
				break;
		}
	}

	render() {
		return (
			<div
				ref={this.containerRef}
				style={{
					height: '100vh',
					overflowY: 'scroll'
				}}
			>
				<IntroductionComponent onTabClick={this.onHeaderTabClick} />
				<FeaturesComponent myref={this.featuresRef} />
				<UseCasesComponent myref={this.useCasesRef} />
				<TechComponent />
				<IntegrationsComponent myref={this.integrationsRef} />
				<ContactComponent myref={this.contactRef} />
				<Footer />
			</div>
		);
	}
}

export default App;