import React, { Component } from 'react';
import styles from './integrationsComponent.module.css';
import applyWrappers from 'utils/applyWrappers';
import youtube from 'images/integrations/youtube.png';
import ck from 'images/integrations/ck.png';
import bbc from 'images/integrations/bbc.png';
import bizpunditz from 'images/integrations/bizpunditz.png';
import coursera from 'images/integrations/coursera.png';
import forbes from 'images/integrations/forbes.png';
import googleDrive from 'images/integrations/google-drive.png';
import hbr from 'images/integrations/hbr.png';
import linkedin from 'images/integrations/linkedin.png';
import onedrive from 'images/integrations/onedrive.png';
import s3 from 'images/integrations/s3.png';
import stanford from 'images/integrations/stanford.png';
import udacity from 'images/integrations/udacity.png';
import udemy from 'images/integrations/udemy.png';
import vimeo from 'images/integrations/vimeo.png';

class IntegrationsComponent extends Component {

	renderIntegrations = () => {
		const integrations = [{
				icon: youtube,
				title: "YouTube"
			},
			{
				icon: ck,
				title: "CrossKnowledge"
			},
			{
				icon: bbc,
				title: "BBC"
			},
			{
				icon: bizpunditz,
				title: "Bizpunditz"
			},
			{
				icon: coursera,
				title: "Coursera"
			},
			{
				icon: forbes,
				title: "Forbes"
			},
			{
				icon: googleDrive,
				title: "Google Drive"
			},
			{
				icon: hbr,
				title: "Harvard Business Review"
			},
			{
				icon: linkedin,
				title: "Linkedin"
			},
			{
				icon: onedrive,
				title: "OneDrive"
			},
			{
				icon: s3,
				title: "AWS S3"
			},
			{
				icon: stanford,
				title: "Stanford Business"
			},
			{
				icon: udacity,
				title: "Udacity"
			},
			{
				icon: udemy,
				title: "Udemy"
			},
			{
				icon: vimeo,
				title: "Vimeo"
			},
		]
		let segments = [];

		for(let index = 0; index < integrations.length; index += 2) {
			const segmentStyling = (index/2) % 2 === 0 ? "even-segment" : "odd-segment";
			if (index + 1 >= integrations.length) {
				segments.push(
					<div key={`single-segment-${index}`} styleName={segmentStyling}>
						<div
							key={integrations[index].title}
							styleName="integration"
							title={integrations[index].title}
						>
							<img alt={integrations[index].title} styleName="integration-image" src={integrations[index].icon} />
						</div>
					</div>
				)
				break;
			}
			if ((index/2) % 2 === 0) {
				segments.push(
					<div key={`even-segment-${index}`} styleName={segmentStyling}>
						<div
							key={integrations[index].title}
							styleName="integration"
							title={integrations[index].title}
						>
							<img alt={integrations[index].title} styleName="integration-image" src={integrations[index].icon} />
						</div>
						<div
							key={integrations[index+1].title}
							styleName="integration"
							title={integrations[index+1].title}
						>
							<img alt={integrations[index+1].title} styleName="integration-image" src={integrations[index+1].icon} />
						</div>
					</div>
				)
			}
			else {
				segments.push(
					<div
						key={`odd-segment-${index}`}
						styleName={segmentStyling}
					>
						<div
							key={integrations[index].title}
							styleName="integration"
							title={integrations[index].title}
						>
							<img alt={integrations[index].title} styleName="integration-image" src={integrations[index].icon} />
						</div>
						<div
							key={integrations[index+1].title}
							styleName="integration"
							title={integrations[index+1].title}
						>
							<img alt={integrations[index+1].title} styleName="integration-image" src={integrations[index+1].icon} />
						</div>
					</div>
				)
			}
		}
		return segments;
	}

	render() {
		return (
			<div ref={this.props.myref} styleName="container">
				<div styleName="text-container">
					<div styleName="title-container">
						<div styleName="main-text">Plays well with all your services</div>
						<div styleName="sub-text">Krawler integrates with over 47 services out of the box</div>
					</div>
					<div styleName="integrations-container">
						<div styleName="description-box-container">
							<div styleName="description-box">
								<div styleName="description-header">Plug N Play Integrations</div>
								<div styleName="sub-text">Krawler integrates with a pool of 47+ digital content resources and more than 13 major cloud storage services like Google Drive, Microsoft SharePoint, and AWS S3.</div>
							</div>
							<div styleName="description-box">
								<div styleName="description-header">Custom Integrations</div>
								<div styleName="sub-text">Krawler can also integrate with your company’s LMS, LRS, HRIS systems to enhance your content search capabilities with your custom repositories.</div>
							</div>
						</div>
						<div styleName="integrations-marquee">
							<div styleName="integrations">
								{this.renderIntegrations()}
								{this.renderIntegrations()}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default applyWrappers(IntegrationsComponent, styles);