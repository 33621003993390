import React, { Component } from 'react';
import styles from './introductionComponent.module.css';
import applyWrappers from 'utils/applyWrappers';
import introduction from 'images/introduction.png';
import Header from 'components/header';

class IntroductionComponent extends Component {
	render() {
		return (
			<div styleName="container">
				<Header onTabClick={this.props.onTabClick} />
				<div styleName="text-container">
					<div styleName="main-text">AI-Powered Deep Indexed Search across your content libraries</div>
					<div styleName="sub-text">Search from multiple knowledge sources across multiple formats and get highly relevant results with the help of relevant metadata built using advanced cognitive machine learning models.</div>
				</div>
				<div styleName="image-container">
					<img src={introduction} alt="background" />
				</div>
			</div>
		)
	}
}

export default applyWrappers(IntroductionComponent, styles);