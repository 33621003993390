import React, { Component } from 'react';
import styles from './techComponent.module.css';
import applyWrappers from 'utils/applyWrappers';
import topBg from 'images/tech-bg-top.png';
import bottomBg from 'images/tech-bg-bottom.png';
import search from 'images/tech-search.png';
import segmentation from 'images/tech-segmentation.png';
import duplicates from 'images/tech-duplicates.png';
import insights from 'images/tech-insights.png';
import textAnalytics from 'images/tech-text-analytics.png';

class TechComponent extends Component {

	renderTech = (iconSrc, title, description) => {
		return (
			<div styleName="tech">
				<div styleName="image-container">
					<img src={iconSrc} alt={title} />
				</div>
				<div styleName="text-container">
					<div styleName="main-text">{title}</div>
					<div styleName="sub-text">{description}</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<div styleName="container">
				<div styleName="top-bg">
					<img src={topBg} alt="background" />
				</div>
				<div styleName="bottom-bg">
					<img src={bottomBg} alt="background" />
				</div>
				<div>
					<div styleName="title">The (secretely) kickass tech we used</div>
					<div styleName="tech-container">
						{this.renderTech(
							search,
							"AI-Powered Deep Indexed Search",
							"By performing deep indexing of your content to extract and create relevant metadata, the system generates a search-relevancy score based on the user's preferences to enhance the search experience across the content library"
						)}
						{this.renderTech(
							segmentation,
							"Computational Linguistic Segmentation",
							"Advanced NLP based techniques for subdividing your content into multiple sections, representing sub-topics by identifying patterns of lexical co-occurrence and distribution which help in information retrieval across multiple formats"
						)}
						{this.renderTech(
							duplicates,
							"Advanced Duplicate Detection",
							"The ML-based Shingle Algorithmic Techniques detects duplicates within your pool of contents and flags them automatically, by generating a custom deduplication score thereby giving you more relevant results than duplicates"
						)}
						{this.renderTech(
							insights,
							"AI-Powered Content Insights",
							"Using custom models based on Computer Vision, Audio and OCR Techniques, the system extracts relevant insights from the content across different libraries, enhancing the discovery and improving the productivity of your team"
						)}
						{this.renderTech(
							textAnalytics,
							"Deep Text Analytics",
							"Krawler evaluates sentiment and topics to understand what users want, extract relevant summaries, and detect named entities out of textual contents using Advanced NLP based text algorithms to map your content into relevant topics"
						)}
					</div>
				</div>
			</div>
		)
	}
}

export default applyWrappers(TechComponent, styles);