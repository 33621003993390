import React, { Component } from 'react';
import styles from './featuresComponent.module.css';
import applyWrappers from 'utils/applyWrappers';
import features from 'images/features.png';
import halfCircle from 'images/half-circle.png';
import circle from 'images/circle.png';
import libraries from 'images/feature-unified-content-libraries.png';
import search from 'images/feature-smart-search.png';
import analytics from 'images/feature-analytics.png';
import security from 'images/feature-security.png';
import review from 'images/feature-review.png';
import collaboration from 'images/feature-collaboration.png';

class FeaturesComponent extends Component {

	renderFeatures = () => {
		const features = {
			'Unified Content Libraries': {
				icon: libraries,
				description: 'A single, unified platform connected to all your digital content libraries like Microsoft SharePoint, Google Drive, AWS S3, etc.'
			},
			'Smart Search': {
				icon: search,
				description: 'Automatically extract hidden metadata using cognitive machine learning models to help enhance and optimize your search results'
			},
			'Analytics': {
				icon: analytics,
				description: 'Gain more metrics to analyze the depth of your content library, user analytics, search trends of your team with interactive dashboards'
			},
			'Security and Trust': {
				icon: security,
				description: 'Krawler has been designed keeping security in mind. All the access, data sovereignty, and information barriers can be completely customized'
			},
			'Review Content': {
				icon: review,
				description: 'Make edits to your content, review, add custom tags, descriptions to customize and further enhance your content using simple user interfaces'
			},
			'Team Collaboration': {
				icon: collaboration,
				description: 'Make working collaborative by sharing your search results and playlists. You can easily share and search with built-in Slack integration'
			}
		}
		return Object.keys(features).map(feature => {
			const { icon, description } = features[feature];
			return (
				<div key={feature} styleName="feature">
					<div styleName="icon">
						<img src={icon} alt={feature} />
					</div>
					<div styleName="feature-text-container">
						<div styleName="feature-main-text">{feature}</div>
						<div styleName="sub-text">{description}</div>
					</div>
				</div>
			)
		});
	}

	render() {
		return (
			<div ref={this.props.myref} styleName="container">
				<div styleName="half-circle">
					<img src={halfCircle} alt="background" />
				</div>
				<div styleName="full-circle">
					<img src={circle} alt="background" />
				</div>
				<div styleName="feature-heading-container">
					<div styleName="image-container">
						<img src={features} alt="background" />
					</div>
					<div styleName="text-container">
						<div styleName="main-text">Not your average search</div>
						<div styleName="sub-text">
							Leveraging the power of Machine Learning Algorithms, we analyze your content better by extracting metadata to create sophisticated content models and make it easier to search. Using state of the art NLP Techniques, we introduce relevant and coherent sections within your content to improve your search on a granular level and quickly find what you are looking for.
						</div>
					</div>
				</div>
				<div styleName="features-container">
					{this.renderFeatures()}
				</div>
			</div>
		)
	}
}

export default applyWrappers(FeaturesComponent, styles);