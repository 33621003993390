import React, { Component } from 'react';
import styles from './contactComponent.module.css';
import applyWrappers from 'utils/applyWrappers';
import topCircle from 'images/usecases-bg-1.png';
import bottomCircle from 'images/usecases-bg-2.png';

class ContactComponent extends Component {
	render() {
		return (
			<div ref={this.props.myref} styleName="container">
				<div styleName="top-circle">
					<img src={topCircle} alt="background" />
				</div>
				<div styleName="bottom-circle">
					<img src={bottomCircle} alt="background" />
				</div>
				<div styleName="text-container">
					<div styleName="title-container">
						<div styleName="main-text">Can’t wait to try Krawler out?</div>
						<div styleName="sub-text">Change the way your team searches, today!</div>
					</div>
					<div
						styleName="contact-button"
						onClick={() => window.open("http://www.knolskape.com/contact", "_blank")}
					>Get in touch</div>
				</div>
			</div>
		)
	}
}

export default applyWrappers(ContactComponent, styles);