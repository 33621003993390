import React, { Component } from 'react';
import styles from './footer.module.css';
import applyWrappers from 'utils/applyWrappers';

class Footer extends Component {
	render() {
		return (
			<div styleName="container">
				<div styleName="title">krawler.</div>
				<div styleName="text-container">
					<div>Made with <span role="img" aria-label="Heart">❤️</span> by 
					<span
						styleName="footer-button"
						onClick={() => window.open("https://www.knolskape.com", "_blank")}
					>
						KNOLSKAPE
					</span>. All rights reserved</div>
					<div styleName="footer-button-container">
						<span
							styleName="footer-button"
							onClick={() => window.open("https://www.knolskape.com/knolskape-terms-and-conditions/", "_blank")}
						>
							Terms of Service
						</span>
						<span styleName="separator">•</span>
						<span
							styleName="footer-button"
							onClick={() => window.open("https://www.knolskape.com/knolskape-terms-and-conditions/#privacy-policy", "_blank")}
						>
							Privacy Policy
						</span>
					</div>
				</div>
			</div>
		)
	}
}

export default applyWrappers(Footer, styles);