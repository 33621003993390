import React, { Component } from 'react';
import styles from './header.module.css';
import applyWrappers from 'utils/applyWrappers';

class Header extends Component {
	render() {
		return (
			<div styleName="container">
				<div styleName="title">krawler.</div>
				<div styleName="tab-container">
					<div styleName="tab-text" onClick={this.props.onTabClick.bind(this, 'features')}>Features</div>
					<div styleName="tab-text" onClick={this.props.onTabClick.bind(this, 'useCases')}>Use Cases</div>
					<div styleName="tab-text" onClick={this.props.onTabClick.bind(this, 'integrations')}>Integrations</div>
					<div styleName="contact-text tab-text" onClick={this.props.onTabClick.bind(this, 'contact')}>Contact us</div>
				</div>
			</div>
		)
	}
}

export default applyWrappers(Header, styles);